<template>

  <div>

    <v-btn icon @click="navigateBack" class="floating-button">
          <v-icon>mdi-arrow-left</v-icon>
    </v-btn>

    
    <v-container class="header-area" @click="navigateToPerfumes">
            <h1 class="header-title-mobile">
                  <span class="underline">Perfume</span> Prophet.
              </h1>
    </v-container>
   
    <br>
    <div class="image-card">
    <div class="img-container">
      <img :src="allImages[perfume.image_name] || require('@/assets/images/00_perfume_icon.webp')" alt="Perfume" class="perfume-img">
    
      <FavoritesToggle :perfumeId="perfume.image_name" :top="283" :left= "-10" />
    </div>
  </div>

  <div class="title-container">
    <span class="perfume-name" :style="{ fontSize: perfume.name.length > 30 ? '1em' : '1.25em' }">{{ perfume.name }}</span>
    <span class="perfume-brand ">{{ perfume.brand }}</span>
  </div>

    
    <br>
    <template>
  <div v-if ="!isMobile" class="scent-types-detail">
    <v-tooltip bottom transition="none" :open-on-click="false" :open-on-hover="!isMobile" v-for="(type, index) in perfume.combined_types.split('-')" :key="`type-${perfume.id}-${index}`">
      <template v-slot:activator="{ on, attrs }">
        <v-chip 
          v-bind="attrs"
          v-on="on"
          :color="getScentTypeColor(type)"          
          class="mobile-chip-custom-detail">

        </v-chip>
      </template>
      <span>{{ type }}</span>
    </v-tooltip>
  </div>
</template>


    <br>
    <div class="perfume-description">
    <PerfumeDescription :perfume="perfume" />
    </div>
    <br>    
    <div class="perfume-notes-list">
    <PerfumeNotesList :perfume="perfume" />
    </div>

    <br>

    <div v-if = "isMobile">
    <ImageCarousel :notes="combinedNotes" :mobile="isMobile" />
      </div>
      <div v-if= "!isMobile" style="user-select: none;">
    <ImageCarousel2 :notes="combinedNotes"/>
    </div>
    <br><br><br><br><br><br><br<br>


<div class="footer">
  <v-btn class = "amazon-btn" 
  :href="generateAmazonLink(perfume.brand, perfume.name, perfume.concentration)"
  target="_blank"
  color="black"
  icon>  
  Check Amazon
</v-btn>

</div>




  </div>

  

</template>

<script>
import axios from 'axios';
import PerfumeDescription from './PerfumeDescription.vue';
import PerfumeNotesList from './PerfumeNotesList.vue';
import ImageCarousel from './ImageCarousel.vue';
import ImageCarousel2 from './ImageCarousel2.vue';
import FavoritesToggle from './FavoritesToggle.vue';
import { getScentTypeColor } from '@/utils/colorMapping.js';


export default {
  props: ['image_name', 'id', 'brand' ,'description', 'scentgn'],
  components: {
    PerfumeDescription,
    ImageCarousel,
    ImageCarousel2,
    PerfumeNotesList,
    FavoritesToggle
  },
  data() {
    return {
      perfume: {},
      allImages: {}, // for pictures
      isMobile: window.innerWidth < 560, // Initialize based on current width
      favorites: JSON.parse(localStorage.getItem('favorites')) || [],
      
    };
  },
  
  created() {
    this.fetchPerfumeDetails();
    this.allImages = this.importAllImages(); //Images
  },
  mounted() {
    this.$nextTick(() => {
        setTimeout(() => {
          //console.log('TopJump by filterPerfumes'); // Add console log
          window.scrollTo(0, 0);
        }, 2); // Delay by 10 milliseconds so that it works right after the Restored Scroll
      });
      const carousel = this.$refs.carousel;

    // Prevent vertical scrolling when swiping on the carousel
    carousel.addEventListener('touchmove', (event) => {
      event.preventDefault();
    }, { passive: false });

    window.addEventListener('resize', this.onResize); // Add resize listener when component mounts
    },

    beforeDestroy() {
    window.removeEventListener('resize', this.onResize); // Remove listener when component is about to be destroyed
    },

    computed: {
  combinedNotes() {
    // Split 'perfume.notes' and check the number of items
    const allNotes = this.perfume.notes.split(', ').filter(n => n.trim() !== '');
    
    // Split 'notes_strong' to check if it has 4 or more items
    const strongNotes = this.perfume.notes_strong ? this.perfume.notes_strong.split(', ').filter(n => n.trim() !== '') : [];

    // Use 'notes_strong' exclusively if it has 4 or more items
    if (strongNotes.length >= 4) {
      return strongNotes.slice(0, 5); // Limit to the first 5 items
    }

    // Use 'perfume.notes' if it has less than 6 items
    if (allNotes.length < 6) {
      return allNotes.slice(0, 5); // Still apply slice to maintain consistency, though not necessary
    }

    // Split 'notes_medium' in case we need to combine it with 'strong'
    const mediumNotes = this.perfume.notes_medium ? this.perfume.notes_medium.split(', ').filter(n => n.trim() !== '') : [];

    // Combine 'strong' and 'medium' notes and limit to 5 items
    return [...strongNotes, ...mediumNotes].slice(0, 5);
  }
},



  methods: {
    async fetchPerfumeDetails() {
      console.log("Attempting to fetch details for:", this.image_name); // Log the image_name received
      try {
        const response = await axios.get(`${process.env.VUE_APP_API_URL}/perfumes/${this.image_name}`);
        this.perfume = response.data;
        console.log("Received data:", this.perfume); // Log the data received
      } catch (error) {
        console.error('Failed to fetch perfume details:', error);
      }
    },

    
//Get all pictures in
  importAllImages() {
    const context = require.context('@/assets/images/', false, /\.webp$/);
    return context.keys().reduce((images, path) => {
      const key = path.split('./')[1].split('.webp')[0]; // Extract the name without the extension
      images[key] = context(path);
      return images;
    }, {});
  },

  navigateToPerfumes() {
    this.$router.push('/perfumes'); // Navigates one step back in the browser history
    },

    
// NavigateBack method inside your PerfumeDetails.vue component's script
  navigateBack() {
    const lastVisited = sessionStorage.getItem('lastVisited');
    console.log('details Page: Stored last visited was', lastVisited);
    if (lastVisited && (lastVisited.startsWith('/perfumes') || lastVisited.startsWith('/favorites'))) {
      this.$router.go(-1); // Acts as a browser back button if there's a history
      console.log('details Page: Function as back button!');
    } else {
      this.$router.push('/perfumes'); // Redirects to '/perfumes' if accessed directly via link
      console.log('details Page: Function as route to perfumes');
    }
  },


    generateAmazonLink(brand, name, concentration) {
    const combinedName = [brand, name, concentration] // Combine brand, name, and concentration
    .filter(Boolean) // Remove any undefined or empty values
    .join(' '); // Join them with a space
    const normalizedName = combinedName

      .normalize('NFD') // Normalize accents
      .replace(/[\u0300-\u036f]/g, '') // Remove diacritics
      .replace(/\s+/g, ' ') // Replace multiple spaces with one
      .trim() // Trim whitespace
      .toLowerCase() // Lowercase all letters
      .replace(/\b\w/g, (c) => c.toUpperCase()); // Capitalize the first letter of each word

    return `https://www.amazon.com/s?k=${encodeURIComponent(normalizedName)}&tag=perfumeprophe-20`;
  },

  onResize() {
      const currentIsMobile = window.innerWidth < 560;      
      if (this.isMobile !== currentIsMobile) {
        this.isMobile = currentIsMobile;  

      this.$forceUpdate(); // Force Vue to re-render the component if necessary
      }
    },

  getScentTypeColor,

  }
};



</script>



<style scoped>

.img-container {
    max-width: 100%;
    display: flex;
    justify-content: center; 
    overflow: visible;
    border-radius: 8px;
    position: relative;
  }
  
  .perfume-img {
    height: 320px;
    transform: translateY(-3%); /* Adjust position for small screens */
    border-radius: 8px
  }

  .title-container {
  display: flex;
  flex-direction: column; /* This aligns children vertically */
  align-items: center; /* This centers them horizontally in the container */
  justify-content: center; /* This centers them vertically in the container */
}

.perfume-name {
  font-weight: bold;
  font-size: 1.25em;
}

.perfume-brand {
  color: grey;
  font-weight: normal;
  font-size: 1em;
}

.perfume-description {
  color: #333; /* Dark grey for better readability */
  text-align: justify; /* Justify text for a clean look */
  line-height: 1.5; /* Improve readability */
  margin: 0 auto; /* Center align the text box */
  max-width: 860px; /* Maximum width of 900px */
  padding: 0 35px; /* Consistent padding for small screens, adjust as needed */
}

.floating-button {
  position: fixed;
  top: 75px;
  left: 20px;
  z-index: 5;
  background-color: #e6e6e64a !important; /* Semi-transparent background color */
  max-width: 44px; /* Ensure the button only takes up as much space as needed */
  max-height: 44px;
}

.footer {
  position: fixed;
  left: 0;
  bottom: 0;
  height: 85px;
  width: 100%;
  background-color: white;
  text-align: center;
  padding: 10px 0; /* Adjust padding as needed */
  box-shadow: 0 -2px 5px rgba(0,0,0,0.1); /* Optional shadow for better visibility */
  z-index: 500;
}

.perfume-notes-list {
  display: flex;          /* Using flexbox for layout */
  flex-direction: column; /* Stack children vertically */
  align-items: center;    /* Center-align items horizontally in the flex container */
  justify-content: center;/* Center-align items vertically if there is extra space */
  text-align: center;     /* Ensures text within each child is centered as well */
  width: 100%;            /* Take full width of the container it's within */
  margin-top: 20px;       /* Optional: adds some space above the list */
  margin-bottom: 20px;    /* Optional: adds some space below the list */
}

.amazon-btn {
  background-color: black;
  color: white !important;
  width: 198px;
  height: 39px;
  border-radius: 8px; 
}

.scent-types-detail {
    display: flex;
    flex-wrap: wrap;
    gap: 5px;
    margin-bottom: 15px;
    align-items: center;  
    justify-content: center;
    border-radius: 8px !important;
  }

  .mobile-chip-custom-detail {
    font-size: 0.9em; 
    color: white; 
    width: 28px !important;
    height: 28px !important;    
    text-align: center; 
    justify-content: center;
    border-radius: 8px !important;
    opacity: 100%;
  }


/* Example to hide dots - adjust the selector based on actual implementation */
.vue-concise-slider .dots-selector {
    display: none;
}


</style>