<template>
    <v-app :style="{ backgroundColor: $vuetify.theme.currentTheme.background }">
      <v-main>  
        
        
        <v-container :class="isMobile ? 'startpage-container-mobile' : 'startpage-container-flex'">
          
            <v-col cols="12" class="text-left">
              <div v-if="isIOSApp()" style="height: 40px;"></div> <!-- Adjust the height as necessary -->
              <h1 class="app-title">
                <span class="underline">Perfume</span> Prophet.
              </h1>
            </v-col>

            <v-btn icon @click="navigateToExplorePage" class="floating-button">
          <v-icon>mdi-arrow-left</v-icon>
        </v-btn>
                
            
               <!-- Iterate through explore data and create cards -->
          <v-row class="px-2">
          <v-col
            v-for="(item, index) in exploreData"
            :key="index"            
            cols="12"
            sm="6"
            md="4"
            lg="3"
            class="d-flex pa-1"
          >
          <v-card 
            @click="navigateToPerfumes(item.note1, item.note2, item.type1, item.type2, item.gender, item.brand, 
                                      item.intensity, item.longevity, item.price)"
            class="custom-card"
            flat
            tile
                      
            >
            
              <v-img
                :src="getImage(item.image)"
                height="360px"
                gradient="to top, rgba(0,0,0,.04), rgba(0,0,0,.15)"
                style="border-radius: 6px;"
              ></v-img>
              <v-card-title class="custom-card-title px-0 py-0">{{ item.title }}</v-card-title>

            </v-card>
          </v-col>

          </v-row>


          
        </v-container>
      </v-main>
    </v-app>
  </template>
  
  <script>
import axios from 'axios'; // Import axios for HTTP requests
import { Capacitor } from '@capacitor/core';



export default {
  name: 'ExplorePage',
  data() {
    return {
      exploreData: [], // Array to hold explore data
      imageMap: {}, // Object to map image names to image URLs
      displayedCards: [], // Array to hold shuffled and filtered data
      defaultImage: require('@/assets/explore_images/explore_image1.webp'), // Default image
      isMobile: window.innerWidth < 750, // Initialize based on screen size
    };
  },
  methods: {
    
  async fetchExploreData() {
      try {
        const response = await axios.get(`${process.env.VUE_APP_API_URL}/explore`);
        // Filter out the item with title 'title' without shuffling the data
        const filteredData = response.data.filter(item => item.title !== 'title');

        // Select the first 12 items from the filtered array without shuffling
        this.displayedCards = filteredData.slice(0, 12);
        this.exploreData = filteredData;
      } catch (error) {
        console.error('Error fetching explore data:', error);
      }
    },

    loadImages() {
      const context = require.context('@/assets/explore_images', false, /\.webp$/);
      context.keys().forEach((key) => {
        const imageName = key.match(/[^/]+(?=\.webp$)/)[0]; // Extract the image name without the extension
        this.imageMap[imageName] = context(key);
      });
    },

    getImage(imageName) {
      // Return the mapped image or the default image if not found
      return this.imageMap[imageName] || this.defaultImage;
    },

    navigateToExplorePage() {
      this.$router.push({ name: 'ExplorePage' });
      console.log('ExploreAllPage; Navigating to ExplorePage');
    },

  onResize() {
    this.isMobile = window.innerWidth < 560; // Define mobile Device
  this.$forceUpdate();
  },

  beforeDestroy() {
    window.removeEventListener('resize', this.onResize);  
  },

    capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
  },

   isIOSApp() {
    return Capacitor.isNativePlatform() && Capacitor.getPlatform() === 'ios';
  },


  navigateToPerfumes(note1, note2, type1, type2, gender, brand, intensity, longevity, price) {
  const query = {};

  // Build the search query from note1 and note2
  const searchTerms = [note1, note2].filter(Boolean).join(' '); // Combine notes without converting to lowercase

  if (searchTerms) {
    query.search = searchTerms;
  }

  // Format types with capitalized first letter
  const types = [];
  if (type1) types.push(this.capitalizeFirstLetter(type1));
  if (type2) types.push(this.capitalizeFirstLetter(type2));
  if (types.length) query.type = types.join(',');

// Add gender to the query if it exists
  // Add gender to the query if it exists
  if (gender) {
    query.gender = gender.toLowerCase(); // Ensure gender is lowercase in the URL
  }
  // Directly use the brand as it appears in the datasource
  if (brand) {
    query.brand = brand.split(',').map(b => b.trim()).join(',');
  }

    // Add intensity to the query if it exists
    if (intensity) {
    query.intensity = intensity.split(',').map(i => i.trim()).join(',');
  }

  // Add longevity to the query if it exists
  if (longevity) {
    query.longevity = longevity.split(',').map(l => l.trim()).join(',');
  }

  // Add price to the query, ensuring it includes both min and max
  if (price) {
    const priceValues = price.split(',').map(p => parseInt(p.trim(), 10));
    const minPrice = Math.min(...priceValues);
    const maxPrice = Math.max(...priceValues);
    query.price = `${minPrice},${maxPrice}`;
  }

   // Debug output to check query parameters
  
   query.fromTile = 'true'; // Pass as a string to ensure consistency

  console.log('Navigating with query:', query);

  this.$router.push({
  name: 'PerfumeDataTable',
  query,
}).then(() => {
  console.log('ExploreAllPage; Navigated to PerfumeDataTable with query:', query);
  window.scrollTo(0, 0); // Scroll to top after navigation
  console.log('ExploreAllPage; Scrolled to top after navigation');
}).catch((error) => {
  console.error('ExploreAllPage; Error navigating to PerfumeDataTable:', error);
});

},

  },
  mounted() {
    this.onResize();
    window.addEventListener('resize', this.onResize);
    this.fetchExploreData(); // Load data when component mounts
    this.loadImages(); // Load images when component mounts
  }
};

</script>

  
<style scoped>  

.floating-button {
  position: fixed;
  top: 75px;
  left: 22px;
  z-index: 5;
  background-color: #e6e6e64a !important; /* Semi-transparent background color */
  max-width: 44px; /* Ensure the button only takes up as much space as needed */
  max-height: 44px;
}


</style>

  