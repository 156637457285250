<template>
  <v-app :style="{ backgroundColor: $vuetify.theme.currentTheme.background }"
         @touchstart="startTouch" 
         @touchend="endTouch">
     <div v-if="isIOSApp()" class="ios-status-bar-padding"></div>
    <v-main>
      <keep-alive include="ExplorePage, ExploreAllPage">
      <router-view />
    </keep-alive>
    </v-main>

    <!-- Bottom Navigation Bar -->
<v-bottom-navigation
  v-if="toolbarVisible && showNavBar"
  app
  fixed
  grow
  class="bottom-nav-bar"
   :style="isIOSApp() ? 'height: 74px; padding-bottom: 93px;' : 'height: 74px;'"
>
  <!-- Inspiration Button -->
  <v-btn
    @click="navigateToLastInspirationRoute"
    class="nav-btn inspiration-btn"
    :class="{ active: isInspirationActive }"
  >
    <v-icon style="font-size: 27px;">{{ isInspirationActive ? 'mdi-lightbulb' : 'mdi-lightbulb-outline' }}</v-icon>
    <span style="display: block; height: 3px; color: white;"> ...</span>
    <span>Inspiration</span>
  </v-btn>

  <!-- Search Button -->

<v-btn
  :to="{ path: '/perfumes' }"
  class="nav-btn"
  :class="{ active: $route.path === '/perfumes' }"
>
  <v-icon style="font-size: 27px;">
    mdi-magnify
    <!--  {{ hasQueryParams ? 'mdi-restart' : 'mdi-magnify' }} -->
  </v-icon>
  <span style="display: block; height: 3px; color: white;">...</span>
  Search
  <!-- <span>{{ hasQueryParams ? 'Restart Search' : 'Search' }}</span> -->
</v-btn>


  <!-- Favorites Button -->
  <v-btn
    :to="{ path: '/favorites' }"
    class="nav-btn"
    :class="{ active: $route.path === '/favorites' }"
  >
    <v-icon style="font-size: 27px;">{{ $route.path === '/favorites' ? 'mdi-heart' : 'mdi-heart-outline' }}</v-icon>
    <span style="display: block; height: 3px; color: white;"> ...</span>
    <span>Favorites</span>
  </v-btn>

      <!-- Info Button -->
    <v-btn
      @click="toggleInfoDialog"        
      class="nav-btn"      
    >
    <v-icon style="font-size: 27px; color: #606060;">mdi-information-outline</v-icon>
      <span style="display: block; height: 3px; color: white;"> ...</span> <!-- Add a small gap -->
      <span style="color: #606060">Info</span>
    </v-btn>


</v-bottom-navigation>


<!-- Info Dialog -->
<v-dialog
      v-model="isInfoDialogOpen"
      width="650"
      
    >
      <v-card class="dialog-card">
        <!-- Header with close button -->
        <v-card-title class="dialog-header">
          <v-btn icon @click="isInfoDialogOpen = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>

        <!-- Dialog content -->
        <v-card-text class="dialog-content">
          <LegalInfo />
        </v-card-text>

        <!-- Footer with Ok button -->
        <v-card-actions class="dialog-footer">
          <v-spacer></v-spacer>
          <v-btn color="black" dark class="dialog-button" @click="isInfoDialogOpen = false">Ok</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <cookie-consent />
  </v-app>
</template>


<script>
import LegalInfo from './components/LegalInfo.vue';
import CookieConsent from './components/CookieConsent.vue';
import { Capacitor } from '@capacitor/core';



export default {
  name: 'App',
  data() {
    return {
      isInfoDialogOpen: false,
      scrollY: 0, // Track the current scroll position
      toolbarVisible: true,
      lastScrollY: 0,
      buttonBottom: '85px',
      isMobile: false,
      showNavBar: true, 
      savedScrollPositions: {}, // Map URLs to scroll positions
      startX: null, // Initial touch X position for swipe detection
      startY: null  // Initial touch Y position for swipe detection
    };
  },

  components: {
    LegalInfo,
    CookieConsent
  },
  
  computed: {
    isInspirationActive() {
      return this.$route.path === '/explore' || this.$route.path === '/explore_all';
    },

     hasQueryParams() {
    // Check if there are any query parameters in the current route
    return Object.keys(this.$route.query).length > 0;
  },
  },

  methods: {
  // Detect the start of the touch (initial position)
   startTouch(event) {
    if (this.isIOSApp()) {
      this.startX = event.touches[0].clientX;
      this.startY = event.touches[0].clientY;
      console.log('App.vue; Start touch detected: ', this.startX, this.startY); // Debugging log
    }
  },
endTouch(event) {
  if (this.isIOSApp() && this.startX !== null && this.startY !== null) {
    const endX = event.changedTouches[0].clientX;
    const endY = event.changedTouches[0].clientY;

    const diffX = this.startX - endX;
    const diffY = this.startY - endY;

    if (Math.abs(diffX) > Math.abs(diffY)) {
      if (diffX > 50) {
        console.log('App.vue; Swipe left: Go forward');  // Debugging log
        this.$router.go(1); // Go forward
      } else if (diffX < -50) {
        // Check if going back would reach the StartPage
        if (this.$route.path !== '/' || !this.isIOSApp()) {
          console.log('App.vue; Swipe right: Go back');  // Debugging log
          this.$router.go(-1); // Go back only if not at StartPage on iOS
        }
      }
    }

    // Reset the start positions
    this.startX = null;
    this.startY = null;
  }
},

  // Existing methods:
  navigateToLastInspirationRoute() {
    const lastRoute = this.loadLastInspirationRoute();
    if (this.$route.path !== lastRoute) {
      this.$router.push({ path: lastRoute });
    }
  },
  toggleInfoDialog() {
    this.isInfoDialogOpen = !this.isInfoDialogOpen;
  },

  
   handleScroll() {
      const currentScrollY = window.scrollY;

      // Save the scroll position for the current route
      const currentUrl = this.$route.fullPath; // Full path includes query parameters
      this.savedScrollPositions[currentUrl] = currentScrollY;

      // Save console.log(`Saved scroll position for ${currentUrl}: ${currentScrollY}`); //
    },


  onResize() {
    this.isMobile = window.innerWidth < 750;
    this.$forceUpdate();
  },
  updateNavBarVisibility() {
    const allowedRoutes = ['/explore', '/explore_all', '/perfumes', '/favorites'];
    this.showNavBar = allowedRoutes.includes(this.$route.path);
  },
  saveLastInspirationRoute(route) {
    if (route.path === '/explore' || route.path === '/explore_all') {
      localStorage.setItem('lastInspirationRoute', route.path);
    }
  },
  loadLastInspirationRoute() {
    const lastRoute = localStorage.getItem('lastInspirationRoute');
    return lastRoute ? lastRoute : '/explore'; // Default to '/explore'
  },



restoreScrollPosition() {
      const currentUrl = this.$route.fullPath; // Full path includes query parameters
      const savedScrollY = this.savedScrollPositions[currentUrl];

   console.log('App.vue; restoreScrollPosition called');
   console.log('App.vue; Current route:', currentUrl);
   console.log('App.vue; Saved scroll Y:', savedScrollY);

   if (
    (this.$route.path === '/perfumes' || 
     this.$route.path === '/explore' || 
     this.$route.path === '/explore_all') && 
    savedScrollY === undefined
  ) {
    console.log(`App.vue; Resetting scroll position to top for ${this.$route.path}`);
    this.$nextTick(() => {
      window.scrollTo(0, 0);
    });
  } else if (savedScrollY !== undefined) {
    this.$nextTick(() => {
      console.log(`App.vue; Restoring scroll to ${savedScrollY}`);
      window.scrollTo(0, savedScrollY);
    });
  } else {
    console.log('App.vue; No saved scroll position found.');
  }
},


isIOSApp() {
  const platform = Capacitor.getPlatform();
  // Debugging log /// console.log('Current platform: ', platform); // Debugging log
  return Capacitor.isNativePlatform() && platform === 'ios';
},

isOnlyItemsChanged(from, to) {
    // Create copies of the query objects without the 'items' parameter
    const fromQuery = {...from.query};
    const toQuery = {...to.query};
    delete fromQuery.items;
    delete toQuery.items;

    // Convert query objects to strings for comparison
    const fromQueryStr = JSON.stringify(fromQuery);
    const toQueryStr = JSON.stringify(toQuery);

    // Check if queries are the same and 'items' is the only difference
    return fromQueryStr === toQueryStr && from.query.items !== to.query.items;
  },

},


  mounted() {
  console.log("App.vue; App is mounted!");  // Add this to check if logs are showing at all
  console.log('App.vue; Current route:', this.$route.fullPath);
  
  
  this.onResize();
  window.addEventListener('resize', this.onResize);
  window.addEventListener('scroll', this.handleScroll);
  this.updateNavBarVisibility();
  

   // Skip start page if on iOS
  if (this.isIOSApp() && this.$route.path === '/') {
    this.$router.push('/perfumes');
  }

  // Restore scroll position if navigating back to an Inspiration route
  if (this.isInspirationActive) {
    console.log('App.vue; Restoring scroll position for Inspiration route');
    this.restoreScrollPosition();
  }
},

  beforeDestroy() {
    window.removeEventListener('resize', this.onResize);
    window.removeEventListener('scroll', this.handleScroll);
    
  },

  
watch: {
  $route(to, from) {
    this.updateNavBarVisibility();
    this.saveLastInspirationRoute(to);

    //console.log('App.vue; Route changed');
    console.log('App.vue; From route:', from.fullPath);
    console.log('App.vue; To route:', to.fullPath);

    // Restore the scroll position if navigating back to an Inspiration route or Perfumes
    // Restore scroll position when the app is loaded
       // Check if the only difference in the route query is the 'items' parameter
       if (this.isOnlyItemsChanged(from, to)) {
      console.log('Change triggered by Load More button; skipping scroll restoration.');
    } else {
      this.restoreScrollPosition();
    }
  
  },
},



};



</script>

<style>
.v-application {
  background-color: var(--v-background-base) !important;
}

.bottom-nav-bar {
  padding-left: 10px;
  padding-right: 10px;  
  position: fixed;
  bottom: 0;
  width: 100%;
  border-top: 1px solid #ccc;
  z-index: 50 !important;
  
}

.nav-btn {
  display: flex;
  flex-direction: column;
  align-items: center;
  color: #373737 !important;
  background-color: white !important;
  margin-top: 0px; /* Adjust to 15px in Dev Mode, and 0px for prod*/
  z-index: 5 !important ;
}


.nav-btn.active {
  color: black !important; /* Set text and icon color to black */
  font-weight: bold !important; /* Make the text bold */
  z-index: 10 !important ;
}

.nav-btn.active::after {
  /* content: "" !important;  /* Empty content for the line */
  position: absolute !important; /* Position absolute to the button */
  bottom: 2px!important; /* Position the line below the button */
  left: 50% !important; /* Start positioning from the middle */
  transform: translateX(-50%) !important; /* Center the line horizontally */
  width: 75% !important; /* Set the width of the line */
  height: 3px !important; /* Thickness of the line */
  color: black !important; 
  background-color: black !important; /* Color of the line */
  z-index: 15 !important ;
}

/* Only apply styles to the Inspiration button when active */
.nav-btn.inspiration-btn.active .v-icon {
  color: black !important; /* Change icon color to black */
}

.nav-btn.inspiration-btn.active span {
  color: black !important; /* Change text color to black */
}


.nav-btn span {
  font-size: 12px; /* Text size remains the same */
}

.v-bottom-navigation__btn {
  padding: 0 !important;
}

/* Dialog Card Styling */
.dialog-card {
  border-radius: 10px;
  overflow: hidden;
}

/* Header Styling */
.dialog-header {
  align-items: center;
  background-color: white;
  padding: 10px 20px !important;
  border-bottom: 1px solid #ccc;
  position: sticky;
  top: 0;
  z-index: 10;
}

/* Content Styling */
.dialog-content {
  padding: 4px !important;
  color: black;
  font-size: 16px; /* Larger text */
  line-height: 1.5; /* Better readability */
}

/* Footer Styling */
.dialog-footer {
  border-top: 1px solid #ccc;
  padding: 10px 20px !important;
  background-color: white;
  position: sticky;
  bottom: 0;
  z-index: 10;
}


.v-main {
  padding-bottom: calc(74px + env(safe-area-inset-bottom));
  padding-top: env(safe-area-inset-top);
  /* Adjust padding for iOS native app */
  padding-bottom: calc(isIOSApp() ? 94px + env(safe-area-inset-bottom) : 74px + env(safe-area-inset-bottom));
}

.ios-status-bar-padding {
  height: 55px; /* Adjust height as needed */
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  background-color: var(--v-background-base); /* Match the app background */
  z-index: 100; /* Keep above content */
}


</style>
