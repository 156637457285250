<template>

  <v-app> 
  
    <v-container>
        <div>
          <v-app-bar v-if="!isMobile" app color="white" elevation="0" class="app-bar-header">
            <div class="header-background"> <!-- Added this div -->
            <v-container class="header-container">
              <h1 class="header-title" @click="DeleteAllFilters" style="cursor: pointer;">
                <span class="underline">Perfume</span> Prophet.
              </h1>
  
              <v-container fluid>
                <v-row>
                  <v-col cols="11">
                    <v-card flat>
                      <v-card-text class="search-bar-container">                      
                        <v-text-field
                        v-model="preQuery"
                          type="search"
                          clearable
                          solo-inverted
                          flat                
                          hide-details
                          label=""
                          prepend-inner-icon="mdi-heart-outline"
                          class="custom-search-field"
                          background-color="#efefef"
                          color="black"                          
                          @click:prepend-inner="focusSearch"
                          ref="searchInput"
                          @keydown.enter="closeKeyboard"
                          enterkeyhint="search"
                          placeholder="Search in your favorites"
                          style="border-radius: 7px;" 
                        ></v-text-field>                    
                      </v-card-text>
                    </v-card>
                  </v-col>
                  <v-col cols="1">                  
                  </v-col>
                </v-row>
              </v-container>
            </v-container>
          </div> <!-- Added this div -->
          </v-app-bar>
  
          <div v-else>

            <div v-if="isIOSApp()" style="height: 40px;"></div> <!-- Adjust the height as necessary -->
            <h1 class="header-title-mobile" @click="DeleteAllFilters" style="cursor: pointer;">
                <span class="underline">Perfume</span> Prophet.
              </h1>
  
  
            <v-container fluid>
              <v-row>
                <v-col cols="11">
                  <v-card flat>
                    <v-card-text class="search-bar-container-mobile">                    
                      <v-text-field
                        v-model="preQuery"
                        type="search"
                        clearable
                        solo-inverted
                        flat                
                        hide-details
                        prepend-inner-icon="mdi-heart-outline"
                        class="custom-search-field"
                        color="black"
                        background-color="#efefef"
                        @click:prepend-inner="focusSearch"
                        ref="searchInput"
                        enterkeyhint="search"
                        @keydown.enter="closeKeyboard"
                        placeholder="Search in your favorites"
                        style="border-radius: 7px;" 
                    
                      ></v-text-field>
                    
                    </v-card-text>
                  </v-card>
                </v-col>
                <v-col cols="1">                 
                </v-col>
              </v-row>
            </v-container>
          </div>
        </div>
      </v-container>
  
    
  <!-- APP DESIGN: Mobile & Flex -->
  
  <body>
  <template>
  
    <div v-if="!isMobile" class="placeholder"></div>
  
        <div>
          <div :class="isMobile ? 'perfume-container-mobile' : 'perfume-container-desktop'" >
            <div :class="showDetails ? (isMobile ? 'mobile-perfume-item' : 'desktop-perfume-item') : 'perfume-item-compact'"
           v-for="(perfume, index) in paginatedItems" 
           :key="`perfume-${index}`">
      
      
  <!-- Compact View -->
  
  
    <div v-if="!showDetails" class="compact-view-container">
    <!-- Image Container -->
    <div class="compact-image-container">
      <img :src="allImages[perfume.image_name] || require('@/assets/images/00_perfume_icon.webp')" alt="Perfume" class="compact-perfume-image">
    </div>
    <!-- Perfume Info and Scent Types -->
    <div class="compact-content">
      <!-- Perfume Name, Brand, and Rating -->
      <div class="mobile-perfume-info">
        <div v-if="isMobile">
          <span class="flex-perfume-name" :style="{ fontSize: perfume.name.length > 40 ? '0.93em' : (perfume.name.length > 30 ? '0.96em' : '1.12em') }">{{ perfume.name }}</span>
          <br>
          <span class="mobile-perfume-brand" :style="{ fontSize: perfume.name.length > 40 ? '0.93em' : (perfume.name.length > 30 ? '0.96em' : '1.12em') }">{{ perfume.brand }}</span>
        </div>
        <div v-if="!isMobile" class="flex-perfume-info">
          <span class="flex-perfume-name" :style="{ fontSize: perfume.name.length > 40 ? '0.96em' : (perfume.name.length > 30 ? '1.00em' : '1.15em') }">{{ perfume.name }}</span>
          <br>
          <span class="mobile-perfume-brand" :style="{ fontSize: perfume.name.length > 40 ? '0.96em' : (perfume.name.length > 30 ? '1.00em' : '1.15em') }">{{ perfume.brand }}</span>
        </div>
      </div>
  
      <!-- Scent Types as Chips -->
      <div v-if="isMobile" class="mobile-scent-types-compact">
        <v-chip 
          v-for="(type, index) in perfume.combined_types.split('-')"
          :key="`type-${perfume.id}-${index}`"
          :color="getScentTypeColor(type)"
          small
          class="white--text text-uppercase mobile-chip-custom-compact">
          {{ type.toUpperCase() }}
        </v-chip>
      </div>
  
          <!-- Scent Types as Chips -->
          <div v-if="!isMobile" class="mobile-scent-types-compact">
        <v-chip 
          v-for="(type, index) in perfume.combined_types.split('-')"
          :key="`type-${perfume.id}-${index}`"
          :color="getScentTypeColor(type)"
          
          class="white--text text-uppercase mobile-chip-custom-compact">
          {{ type.toUpperCase() }}
        </v-chip>
      </div>
  
  
  
    </div>
  </div>
  
  
      <!-- Normal App View (non-compact)-->
  
      <div v-if="showDetails">
  
      <!-- Perfume Name, Brand, and Rating -->
      <div class="mobile-perfume-info">
  
      <div v-if="isMobile">
        <span class="mobile-perfume-name">{{ perfume.name }}</span>
        <span class="divider">|</span>
        <span class="mobile-perfume-brand">{{ perfume.brand }}</span>
      </div>
  
  
      <div v-if="!isMobile" class ="flex-perfume-info">
        <span class="flex-perfume-name" :style="{ fontSize: perfume.name.length > 40 ? '0.96em' : (perfume.name.length > 30 ? '1.00em' : '1.15em') }">{{ perfume.name }}</span>
        <br>
        <span class="mobile-perfume-brand" :style="{ fontSize: perfume.name.length > 40 ? '0.96em' : (perfume.name.length > 30 ? '1.00em' : '1.15em') }">{{ perfume.brand }}</span>
  
      </div>
    </div>
  
  
            
        <!-- Scent Types as Chips -->
        <div class="mobile-scent-types">
          <v-chip 
            v-for="(type, index) in perfume.combined_types.split('-')"
            :key="`type-${perfume.id}-${index}`"
            :color="getScentTypeColor(type)"
            class="white--text text-uppercase mobile-chip-custom"
            >
            {{ type.toUpperCase() }}
          </v-chip>
        </div>
            
  
  
      <div class="mobile-ratings">
            
        
<!-- Image -->
<div class="image-card" :style="{ backgroundColor: '#FFFFFF' }">
  <div :class="{ 'image-container-mobile': isMobile, 'image-container': !isMobile }" style="position: relative;">
    <img :src="allImages[perfume.image_name] || require('@/assets/images/00_perfume_icon.webp')" alt="Perfume" class="mobile-perfume-image" 
    @click="goToDetails(perfume.image_name, perfume.id, perfume.name, perfume.brand, perfume.concentration, perfume.scentgn)"
    >
   </div>
   <FavoritesToggle :perfumeId="perfume.image_name" :top="210" :left= "10"  />
</div>

<div style="height:30px"></div>
  
    <v-row>
  
 
  
  <!-- Notes Chips -->

<div :key="chipsKey" class="scrollable-chips-container" ref="chipsContainer">
   
   <div class="chips-row">
     <!-- Strong Notes -->
     <v-chip
       v-for="(note, index) in perfume.notes_strong.split(',').filter(n => n.trim() !== '')"
       :key="`strong-note-${perfume.id}-${index}`"
       class="mobile-chip-strong-notes"
       @click="toggleNoteDropdown($event, note.trim(), perfume.id)"
     >
       {{ note.trim() }}
     </v-chip>
     <!-- Medium Notes -->
     <v-chip
       v-for="(note, index) in perfume.notes_medium.split(',').filter(n => n.trim() !== '')"
       :key="`medium-note-${perfume.id}-${index}`"
       class="mobile-chip-medium-notes"
       @click="toggleNoteDropdown($event, note.trim(), perfume.id)"
     >
       {{ note.trim() }}
     </v-chip>
     <!-- Weak Notes -->
     <v-chip
       v-for="(note, index) in perfume.notes_weak_adj.split(',').filter(n => n.trim() !== '')"
       :key="`weak-note-${perfume.id}-${index}`"
       class="mobile-chip-weak-notes"
       @click="toggleNoteDropdown($event, note.trim(), perfume.id)"
     >
       {{ note.trim() }}
     </v-chip>
   </div>
 
     
 </div>
 
 
  

  
<!-- Ratings Box for Longevity, Projection, and Price -->


<v-col cols="12" class="py-1 ma-0">
    <div class="mobile-rating-box">           
      <v-row>
        <v-col cols="12" class="py-1 ma-0">          
          <!-- Row 1: Projection -->
          <v-row class="rating-row">
            <v-col cols="4" class="rating-label">Intensity</v-col>
            <v-col cols="8" class="rating-bar-container">
              <div :style="generateBarContainerStyle()">
                <div class="rating-bar" :style="ratingBarStyle(perfume, sillageRating(perfume.sillage))"></div>
              </div>
            </v-col>
          </v-row>               
          
          <!-- Row 2: Longevity -->
          <v-row class="rating-row">
            <v-col cols="4" class="rating-label">Longevity</v-col>
            <v-col cols="8" class="rating-bar-container">
              <div :style="generateBarContainerStyle()">
                <div class="rating-bar" :style="ratingBarStyle(perfume, longevityRating(perfume.longevity))"></div>
              </div>
            </v-col>
          </v-row>
  
        <!-- Row 3: Price, EDT, and Year -->
        <v-row class="rating-row align-center">
          <v-col cols="4" class="rating-label pa-0">
            <span v-for="n in priceToNumber(perfume.price)" :key="n" class="price-symbol">$</span>
          </v-col>
          <v-col cols="4" class="text-center" style="color:grey">
            <span :style="{ whiteSpace: 'nowrap',  textOverflow: 'ellipsis', display: 'inline-block' }">
            {{ perfume.concentration }}
            </span>
          </v-col>

          <v-col cols="4" class="text-right" style="color:grey">
            <span>{{ perfume.year && !isNaN(parseInt(perfume.year, 10)) && parseInt(perfume.year, 10) > 0 ? parseInt(perfume.year, 10) : '' }}</span>
          </v-col>
        </v-row>


        </v-col>
      </v-row>
    </div>
  </v-col>
  
      </v-row>
  
      </div>
      </div>
  
          </div>
          </div>
          
          
         
          <v-btn
        fab
        fixed
        right
        :style="{
        bottom: isIOSApp() ? 'calc(' + buttonBottom + ' + 18px)' : buttonBottom,
        backgroundColor: 'transparent',
        boxShadow: 'none'
        }"
        @click="buttonAction"
        class="transparent-btn"
        >
          <v-icon>{{ scrollY > 450 ? 'mdi-arrow-up' : (showDetails ? 'mdi-view-list' : 'mdi-view-grid') }}</v-icon>
        </v-btn>
  
  
          <div v-if="filteredPerfumes.length > itemsPerPage"> 
            <v-pagination
            v-model="currentPage"
            :length="totalPages"
            :total-visible="5"
            class="custom-pagination"
          ></v-pagination>
          <br>
          <br>    
          </div>
  
            
          <div v-else-if="filteredPerfumes.length === 0" class="no-results-container">
    <div class="no-results-message">
      There are no perfumes in your favorites, yet. 
      <br>
      <br>
      Find a cool scent and press 
      <v-icon color=#dcdcdc >mdi-heart</v-icon> to add it to the favorites.
    </div>
  
    <!-- Remove Gender Filter Button -->
    <v-btn v-if="selectedScentgender.length > 0"
           @click="removeFilter('gender')"
           color="primary"
           text>Remove Gender Filter</v-btn>
  
    <!-- Remove Intensity Filter Button -->
    <v-btn v-if="selectedSillage.length && selectedSillage.toString() !== [1, 4].toString()"
           @click="removeFilter('sillage')"
           color="primary"
           text>Remove Intensity Filter</v-btn>
  
    <!-- Remove Longevity Filter Button -->
    <v-btn v-if="selectedLongevity.length && selectedLongevity.toString() !== [1, 4].toString()"
           @click="removeFilter('longevity')"
           color="primary"
           text>Remove Longevity Filter</v-btn>
  
    <!-- Remove Price Filter Button -->
    <v-btn v-if="selectedPrice.length && selectedPrice.toString() !== [1, 5].toString()"
           @click="removeFilter('price')"
           color="primary"
           text>Remove Price Filter</v-btn>
  
    <!-- Remove Gender Filter Button -->
      <v-btn v-if="selectedBrand.length > 0"
           @click="removeFilter('brand')"
           color="primary"
           text>Remove Brand Filter</v-btn>
  
  
  </div>
  
  
  </div>
      <br>
      <br>
  
    </template>
  
<!--FILTER SECTION-->

</body>


  </v-app>      
</template>


<script>
import axios from 'axios';
import { deleteAllFilters, deleteTempFilters, resetTempFilters, removeFilter } from '@/utils/FilterReset.js';
import { sortPerfumes, shuffleArray } from '../utils/sort.js';
import { getScentTypeColor } from '@/utils/colorMapping.js';
import { filterPerfumes, previewFilteredPerfumes} from '../utils/Filters.js';
import { Capacitor } from '@capacitor/core';
import FavoritesToggle from './FavoritesToggle.vue';


export default {
  data() {
    return {  
      
      totalVisibleRows: 20, // Initial number of items to display
      incrementAmount: 20, // Number of items to add on each scroll to the bottom        
      perfumes: [],
      filteredPerfumes: [],
      brands: [],      
      type: [],
      scentgn: [], 
      search: '',
      preQuery: '', // new property for preQuery
      searchQuery: '', // for searchbar
      
      selectedBrand: [], 
      selectedScentgender: [],
      selectedType: {},
      selectedPrice: [1, 5],
      selectedSillage: [1, 4],
      selectedLongevity: [1, 4],
      tempSelectedScentgender: [], 
      tempSelectedType: {},
      tempSelectedBrand: [],
      
      timeout: null,  
      showDetails: true, // New property to control the visibility of detailed ratings
      allImages: {}, // for pictures
      currentPage: 1, // for pagination
      itemsPerPage: 12,
      isDrawerOpen: false, //for sliding filter window
      isInfoDrawerOpen: false, //for sliding filter window
      scrollTop: 0,
      currentSort: '', // current sort parameter
      tempSort: '',
      showBrandOptions: false,
      showBrands: false,
      isMobile: false,
      lastTap: 0,
      lastTappedOption: null, 
      maxExcluded: 8,
      currentLetter: 'A', // Initial letter
      scrollPosition: 0,
      isMouseDown: false,
      startX: 0,
      scrollLeft: 0,
      scrollY: 0,
      lastScrollY: 0, 
      chipsKey: 0,
      
      favorites: JSON.parse(localStorage.getItem('favorites')) || [],

      buttonBottom: '85px', // Default bottom position for the button

      timeMachine: [
        { label: '2009', description: 'release date was 2009' },
        { label: 'before 2009', description: 'was released before 2009' },
        { label: 'after 2009', description: 'was released after 2009' },
        { label: '2009 - 2015', description: 'was released between 2009 and 2015' },
        { label: 'new', description: 'all perfumes that were released this year or last year' },
        { label: 'classic', description: 'on the market for 25 years or longer' },
        { label: 'legendary', description: 'on the market for 50 years or longer' },
      ],
      notes: ['Rose', 'Lavender', 'Lavender Rose'],
    

      tempSelectedPrice: [1, 5],
      priceCategories: ['very low','low', 'medium', 'high', 'very high'],
      priceMarks: 
        { 1: '$',
        2: '$$',
        3: '$$$',
        4: '$$$$',
        5: 'Luxury'},

      tempSelectedLongevity: [1, 4],  // Default range values for longevity
      longevityCategories: ['short', 'medium', 'long', 'very long'],
      longevityMarks: {            
            1: 'Short',
            2: 'Medium',
            3: 'Long',
            4: 'Very Long'
          },

      tempSelectedSillage: [1, 4],    // Default range values for sillage
      sillageCategories: ['low', 'medium', 'strong', 'very strong'],
      sillageMarks: {
            1: 'Silent',
            2: 'Medium',
            3: 'Loud',
            4: 'Very Loud'
          }


    };
  },


  components: {
    FavoritesToggle,
  },  

  
  computed: {
            
    activeFilterCount() {
    let count = 0;

    // Check if the longevity slider is set to its full range
    if (this.selectedLongevity.length > 0 && !(this.selectedLongevity[0] === 1 && this.selectedLongevity[1] === this.longevityCategories.length)) {
      count++;
    }

    // Check if the sillage slider is set to its full range
    if (this.selectedSillage.length > 0 && !(this.selectedSillage[0] === 1 && this.selectedSillage[1] === this.sillageCategories.length)) {
      count++;
    }

    // Check if the price slider is set to its full range
    if (this.selectedPrice.length > 0 && !(this.selectedPrice[0] === 1 && this.selectedPrice[1] === this.priceCategories.length)) {
      count++;
    }

    // Count other selected filters
    count += this.selectedScentgender.length;
    count += this.selectedBrand.length;
    count += Object.keys(this.selectedType).length;

    return count;
  },

  BrandactiveFilterCount() {
    return this.tempSelectedBrand ? this.tempSelectedBrand.length : 0;
  },

  //for Pagination
  paginatedItems() {
    const start = (this.currentPage - 1) * this.itemsPerPage;
    const end = start + this.itemsPerPage;
    return this.filteredPerfumes.slice(start, end);
  },
  totalPages() {
    return Math.ceil(this.filteredPerfumes.length / this.itemsPerPage);
  },

//Sorting for brands filter
sortedBrands() {
    // First, sort the brands alphabetically and then return.
    return this.brands.slice().sort((a, b) => a.localeCompare(b));
  },
  firstRowBrands() {
    // Return brands for the first row (1st, 3rd, 5th, etc.)
    return this.sortedBrands.filter((_, index) => index % 2 === 0);
  },
  secondRowBrands() {
    // Return brands for the second row (2nd, 4th, 6th, etc.)
    return this.sortedBrands.filter((_, index) => index % 2 !== 0);
  },

  
  previewFilteredPerfumes,
  

  },

  watch: {
  preQuery(newValue, oldValue) {
    if (newValue !== oldValue) {
      this.triggerSearch(); // Automatically trigger the search as the user types
    }
  },
 
  currentPage(newValue, oldValue) {
    if (newValue !== oldValue) {
      window.scrollTo(0, 0); // Scrolls to the top of the page
      this.chipsKey += 1;
    }
  },

},



  created() {
    
    this.allImages = this.importAllImages(); //Images
    this.fetchPerfumes().then(() => {
    this.sortPerfumes(); // Initial random sorting

    this.loadFiltersFromUrl(); // Load filters from URL if present

   

  });
  },  
     
  mounted() {
    this.onResize(); // Set initial state based on current window size
    window.addEventListener('resize', this.onResize); // Add resize listener when component mounts
    window.addEventListener('scroll', this.handleScroll);    
    this.filterPerfumes(); // Apply the filters based on the URL
    this.sortPerfumes(); // Sort the perfumes based on the filters
  },

  beforeDestroy() {
    window.removeEventListener('resize', this.onResize); // Remove listener when component is about to be destroyed
    window.removeEventListener('scroll', this.handleScroll);
  },
  
  methods: {
  

    async fetchPerfumes() {
    try {
      // Check if there's already a shuffled dataset in local storage
      const storedPerfumes = localStorage.getItem('shuffledPerfumes');
      
      if (storedPerfumes) {
        // If it exists, use it and filter for favorites

        this.perfumes = JSON.parse(storedPerfumes).filter(perfume => this.favorites.includes(perfume.image_name));

      } else {
        // Otherwise, fetch the data and shuffle it
        const response = await axios.get(`${process.env.VUE_APP_API_URL}/perfumes`);
        
        const allPerfumes = response.data.map((perfume, index) => ({
          ...perfume,
          id: `perfume-${index}` // Ensure unique ID for each perfume
        }));
        
        // Shuffle the dataset and filter for favorites

        this.perfumes = this.shuffleArray(allPerfumes).filter(perfume => this.favorites.includes(perfume.image_name));
        
        // Store the shuffled dataset in local storage
        localStorage.setItem('shuffledPerfumes', JSON.stringify(allPerfumes));
      }
      
      this.filteredPerfumes = this.perfumes.slice(0, this.totalVisibleRows);
      this.brands = [...new Set(this.perfumes.map(perfume => perfume.brand))].sort();
      this.scentgn = [...new Set(this.perfumes.map(perfume => perfume.scentgn))].sort();
      this.longevity = ['short', 'medium', 'long', 'very long'];
      this.sillage = ['low', 'medium', 'strong', 'very strong'];
      this.price = ['low', 'medium', 'high', 'very high'];
      this.type = ['Fresh', 'Flowery', 'Sweet', 'Fruity', 'Citric', 'Woody', 'Creamy', 'Aromatic', 'Powdery', 'Green', 'Aquatic', 
                  'Synthetic', 'Gourmand', 'Leathery', 'Mineral', 'Oriental', 'Smoky', 'Animalic'];
      // Apply any initial filters
      this.filterPerfumes();
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  },
  
  onResize() {
    this.isMobile = window.innerWidth < 560; // Define mobile Device
  this.$forceUpdate();
  },

  // Show hide brands
  toggleBrandsVisibility() {
    this.showBrands = !this.showBrands;
  },

  scrollToTop() {
  window.scrollTo({ top: 0, behavior: 'smooth' });
  },

  focusSearch() {
    this.$refs.searchInput.focus(); // Focus the input field when tab on magnify
  },
  closeKeyboard() {
    // Blur the input field to close the keyboard on mobile devices
    if (this.$refs.searchInput) {
      this.$refs.searchInput.blur();
    }
  },

  handleScroll() {
  const currentScrollY = window.scrollY;

  // Handle toolbar visibility
if (this.isMobile) {
  if (currentScrollY > this.lastScrollY && currentScrollY > 450) {
    // Scrolling down and past 600px
    this.buttonBottom = '35px'; // Set the button to 45px when scrolled more than 600px
  } else if (currentScrollY < this.lastScrollY || currentScrollY <= 450) {
    // Scrolling up or at the top of the page
    this.buttonBottom = '85px'; // Reset the button to 85px when scrolled less than 600px
  }
}

  // Update the last scroll position
  this.lastScrollY = currentScrollY;
  this.scrollY = currentScrollY;
},



    buttonAction() {
    if (this.scrollY > 450) {
      this.scrollToTop();
    } else {
      this.showDetails = !this.showDetails;
    }
  },

  toggleDetails() {
    this.showDetails = !this.showDetails;
  },

    mouseDown(e) {
      this.isMouseDown = true;
      this.startX = e.pageX - e.currentTarget.offsetLeft;
      this.scrollLeft = e.currentTarget.scrollLeft;
      e.currentTarget.style.cursor = 'grabbing'; // Change cursor to grabbing
      document.body.style.userSelect = 'none'; // Prevent text selection
    },
    mouseMove(e) {
      if (!this.isMouseDown) return;
      e.preventDefault();
      const x = e.pageX - e.currentTarget.offsetLeft;
      const walk = (x - this.startX) * 2; // scroll-fast
      e.currentTarget.scrollLeft = this.scrollLeft - walk;
    },
    mouseUp(e) {
      this.isMouseDown = false;
      e.currentTarget.style.cursor = 'grab'; // Change cursor back to grab
      document.body.style.userSelect = ''; // Re-enable text selection
    },
    mouseLeave(e) {
      this.isMouseDown = false;
      e.currentTarget.style.cursor = 'grab'; // Change cursor back to grab
      document.body.style.userSelect = ''; // Re-enable text selection
    },
    touchStart(e) {
      this.isMouseDown = true;
      this.startX = e.touches[0].pageX - e.currentTarget.offsetLeft;
      this.scrollLeft = e.currentTarget.scrollLeft;
    },
    touchMove(e) {
      if (!this.isMouseDown) return;
      const x = e.touches[0].pageX - e.currentTarget.offsetLeft;
      const walk = (x - this.startX) * 2; // scroll-fast
      e.currentTarget.scrollLeft = this.scrollLeft - walk;
    },
    touchEnd() {
      this.isMouseDown = false;
    },

    noteDescription(note) {
  if (note.includes(' ')) {
    // Split the note into words and join them with " AND "
    const words = note.split(' ');
    return `all perfumes that contain ${words.join(' and ')}`;
  } else {
    // For single-word notes, return the default description
    return `all perfumes that contain ${note}`;
  }
},

sendEmail() {
    const email = 'mail@perfumeprophet.com';
    window.location.href = `mailto:${email}`;
  },

  addToSearchQuery(term) {
    if (this.preQuery) {
      this.preQuery += ` ${term}`;
    } else {
      this.preQuery = term;
    }

    if (this.searchQuery) {
      this.searchQuery += ` ${term}`;
    } else {
      this.searchQuery = term;
    }

    this.filterPerfumes(); // Refilter perfumes with the updated search query
  },
  // New method to handle double-click event
  handleChipDoubleClick(term) {
    this.addToSearchQuery(term);
  },

  triggerSearch() {
    this.searchQuery = this.preQuery; // Set the actual search query based on preQuery
   
    
    // Call the method to filter perfumes again
    this.filterPerfumes();
    
  },
  
//Get all pictures in
  importAllImages() {
    const context = require.context('@/assets/images/', false, /\.webp$/);
    return context.keys().reduce((images, path) => {
      const key = path.split('./')[1].split('.webp')[0]; // Extract the name without the extension
      images[key] = context(path);
      return images;
    }, {});
  },

  updateUrlWithFilters() {
    const query = {};

    if (this.searchQuery) {
      query.search = this.searchQuery;
    }

    if (this.selectedScentgender.length) {
      query.gender = this.selectedScentgender.join(',');
    }
    
    // Handle selected and excluded types
    const selectedTypes = Object.keys(this.selectedType).filter(key => this.selectedType[key] === 'selected');
    const excludedTypes = Object.keys(this.selectedType).filter(key => this.selectedType[key] === 'excluded');

    if (selectedTypes.length) {
      query.type = selectedTypes.join(',');
    }
    
    if (excludedTypes.length) {
      query.excludeType = excludedTypes.join(',');
    }

    if (this.selectedBrand.length) {
      query.brand = this.selectedBrand.join(',');
    }
    
    // Add slider values to the URL query
    if (this.selectedPrice[0] !== 1 || this.selectedPrice[1] !== 5) {
      query.price = this.selectedPrice.join(',');
    }
    
    if (this.selectedLongevity[0] !== 1 || this.selectedLongevity[1] !== 4) {
      query.longevity = this.selectedLongevity.join(',');
    }
    
    if (this.selectedSillage[0] !== 1 || this.selectedSillage[1] !== 4) {
      query.intensity = this.selectedSillage.join(',');
    }

    if (JSON.stringify(query) !== JSON.stringify(this.$route.query)) {
      this.$router.push({ query });
    }
    
  },


  loadFiltersFromUrl() {
    const query = this.$route.query;

    if (query.search) {
      this.searchQuery = query.search;
      this.preQuery = query.search; // If you want to reflect it in the preQuery as well
    }

    if (query.gender) {
      this.selectedScentgender = query.gender.split(',');
    }
    
    if (query.type) {
      this.selectedType = query.type.split(',').reduce((acc, type) => {
        acc[type] = 'selected';
        return acc;
      }, {});
    }

    if (query.excludeType) {
      query.excludeType.split(',').forEach(type => {
        this.$set(this.selectedType, type, 'excluded');
      });
    }

    if (query.brand) {
      this.selectedBrand = query.brand.split(',');
    }

    // Load slider values from the URL
    if (query.price) {
      this.selectedPrice = query.price.split(',').map(Number);
    }

    if (query.longevity) {
      this.selectedLongevity = query.longevity.split(',').map(Number);
    }

    if (query.intensity) {
      this.selectedSillage = query.intensity.split(',').map(Number);
    }

    // Add more filters as needed
  },

 syncFiltersWithRoute(query) {
    // Process the search query first
    if (query.search) {
      this.searchQuery = query.search;
      this.preQuery = query.search;
    } else {
      this.searchQuery = '';
      this.preQuery = '';
    }

  // Process type filters (including exclusions)
  this.selectedType = {};
    if (query.type) {
      query.type.split(',').forEach(type => {
        this.$set(this.selectedType, type, 'selected');
      });
    }

    if (query.excludeType) {
      query.excludeType.split(',').forEach(type => {
        this.$set(this.selectedType, type, 'excluded');
      });
    }

    // Now process the other filters
    if (query.gender) {
      this.selectedScentgender = query.gender.split(',');
    } else {
      this.selectedScentgender = [];
    }

    if (query.brand) {
      this.selectedBrand = query.brand.split(',');
    } else {
      this.selectedBrand = [];
    }

    // Update slider values
    if (query.price) {
      this.selectedPrice = query.price.split(',').map(Number);
    } else {
      this.selectedPrice = [1, 5];
    }

    if (query.longevity) {
      this.selectedLongevity = query.longevity.split(',').map(Number);
    } else {
      this.selectedLongevity = [1, 4];
    }

    if (query.intensity) {
      this.selectedSillage = query.intensity.split(',').map(Number);
    } else {
      this.selectedSillage = [1, 4];
    }

    // Apply the filters and search
    this.filterPerfumes();
    this.sortPerfumes();
  },

  

  ratingBarStyle(perfume, ratingValue) {
    const endColor = perfume.dom_color || 'black';
    const widthPercentage = (ratingValue / 5) * 100;
    const style = {
      background: `linear-gradient(to right, #eeeeee, ${endColor})`,
      width: `${widthPercentage}%`,
      height: '100%',
      borderRadius: '2px',
    };

    return style;
  },

  generateBarContainerStyle() {
    return {
      backgroundColor: 'white',
      width: '100%',
      height: '50%',
      position: 'relative',
      borderRadius: '10px',
      overflow: 'hidden', // Ensures the inner bar respects the border radius
    };
  },

    valueRating(perfume) {
      return perfume.someValue; // Replace with your logic
    },

       isIOSApp() {
    return Capacitor.isNativePlatform() && Capacitor.getPlatform() === 'ios';
    },
   


    //Toggle Drawer filter area
    toggleDrawer() {
      this.isDrawerOpen = !this.isDrawerOpen;
      if (this.isDrawerOpen) {
        // Capture current scroll position
        this.scrollPosition = window.scrollY;
        document.body.style.top = `-${this.scrollPosition}px`;
        document.body.classList.add('no-scroll');
    
        // Scroll the filter drawer content to the top
        this.$nextTick(() => {
          if (this.$refs.drawerContent) {
            this.$refs.drawerContent.scrollTop = 0;
          }
        });
        this.resetTempFilters();
      } else {
        // Reset temporary filters to actual filters
        document.body.classList.remove('no-scroll');
        document.body.style.top = '';
        window.scrollTo(0, this.scrollPosition); // Restore scroll position
        this.showBrands = false; // Close brands area
      }
    },


      //Toggle Drawer filter area
      toggleInfoDrawer() {
      this.isInfoDrawerOpen = !this.isInfoDrawerOpen;
      if (this.isInfoDrawerOpen) {
        // Capture current scroll position
        this.scrollPosition = window.scrollY;
        document.body.style.top = `-${this.scrollPosition}px`;
        document.body.classList.add('no-scroll');

        // Scroll the filter drawer content to the top
        this.$nextTick(() => {
          if (this.$refs.InfodrawerContent) {
            this.$refs.InfodrawerContent.scrollTop = 0;
          }
        });
      } else {
        // Reset temporary filters to actual filters
        document.body.classList.remove('no-scroll');
        document.body.style.top = '';
        window.scrollTo(0, this.scrollPosition); // Restore scroll position
      }
    },

 // Removes certain filters when pressing 'Remove Filters' buttons when no results
    removeFilter(filterType) {
      removeFilter(filterType, this);
    },

  toggleBodyScroll(isOpen) {
    if (isOpen) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = '';
    }
  },

toggleFavorite(perfumeId) {
      if (this.isFavorite(perfumeId)) {
        this.favorites = this.favorites.filter(id => id !== perfumeId);
      } else {
        this.favorites.push(perfumeId);
      }
      localStorage.setItem('favorites', JSON.stringify(this.favorites));
    },
    isFavorite(perfumeId) {
      return this.favorites.includes(perfumeId);
    },

  //Filter Reset Button & Sort when exit the filtermenu without apply
  resetTempFilters() {
    resetTempFilters(this);
  },

//Filter Reset Button & Sort (for Delete all Button in Filtermenu)
  DeleteTempFilters() {
    deleteTempFilters(this);
  },

  //Click Logo to start new search
  DeleteAllFilters() {
      deleteAllFilters(this, false);
      this.filterPerfumes();
    },

// Sorting
sortPerfumes() {
      this.filteredPerfumes = sortPerfumes(
        this.filteredPerfumes, 
        this.currentSort, 
        this.priceToNumber, 
        this.searchQuery
      );
    },
    
    shuffleArray(array) {
      return shuffleArray(array);
    },

  toggleScentgender(option) {
    const index = this.tempSelectedScentgender.indexOf(option);
    if (index > -1) {
      this.tempSelectedScentgender.splice(index, 1);
    } else {
      this.tempSelectedScentgender.push(option);
    }
  },

  toggleType(option) {
      const currentTime = new Date().getTime();
      const tapLength = currentTime - this.lastTap;
      const doubleTapThreshold = 210; // Adjusted threshold for double-tap detection

      // Count currently excluded items
      const excludedCount = Object.values(this.tempSelectedType).filter(state => state === 'excluded').length;

      if (this.lastTappedOption === option && tapLength < doubleTapThreshold && tapLength > 0) {
        // Double-tap detected on the same item
        if (this.tempSelectedType[option] === 'excluded') {
          this.$delete(this.tempSelectedType, option); // Bring it back to normal state
        } else {
          if (excludedCount < this.maxExcluded) {
            this.$set(this.tempSelectedType, option, 'excluded');
          } else {
            // Optional: Provide feedback to the user that they can't exclude more items
            
          }
        }
        this.lastTappedOption = null; // Reset last tapped option
      } else {
        // Single tap detected or different item tapped
        const currentState = this.tempSelectedType[option];
        const selectedCount = Object.keys(this.tempSelectedType).filter(key => this.tempSelectedType[key] === 'selected').length;

        if (currentState === 'selected') {
          this.$delete(this.tempSelectedType, option);
        } else if (currentState === 'excluded') {
          this.$delete(this.tempSelectedType, option);
        } else {
          // Only add a new selected if fewer than 15 are already selected
          if (selectedCount < 8) {
            this.$set(this.tempSelectedType, option, 'selected');
          }
        }
      }

      this.lastTap = currentTime; // Update last tap time
      this.lastTappedOption = option; // Update last tapped option
    },
  

toggleBrand(option) {
    const index = this.tempSelectedBrand.indexOf(option);
    if (index > -1) {
      this.tempSelectedBrand.splice(index, 1);
    } else {
      this.tempSelectedBrand.push(option);
    }
  },

  selectTempSort(option) {
    this.tempSort = this.tempSort === option ? '' : option;  
  },
  
  applyFilters() {
    this.selectedScentgender = [...this.tempSelectedScentgender];
    this.selectedLongevity = [...this.tempSelectedLongevity];
    this.selectedSillage= [...this.tempSelectedSillage];
    this.selectedType = { ...this.tempSelectedType };
    this.selectedPrice = [...this.tempSelectedPrice];
    this.selectedBrand = this.tempSelectedBrand;
    this.currentSort = this.tempSort;

    // Update the URL with the selected filters
    this.updateUrlWithFilters();

    this.sortPerfumes();
    this.filterPerfumes(); // Call the filter method 
    this.toggleDrawer(); // Close the drawer
  },
  

  

////APPLY FILTERS AND SEARCH /////
  filterPerfumes,


  // Reset pagination, scroll to top and reset horizontal chips slider
  resetPaginationAndScroll() {
        this.currentPage = 1;
        this.chipsKey += 1; // Update chipsKey to reset scroll position       
    },    

  // convert categories into numerics
  sillageRating(sillage) {
    const ratingValues = {
      'very low': 1,
      'low': 2,
      'medium': 3,
      'strong': 4,
      'very strong': 5
    };
    return ratingValues[sillage.toLowerCase()] || 0; // Default to 0 if not found
  },

  longevityRating(longevity) {
    const ratingValues = {
      'very short': 1,
      'short': 2,
      'medium': 3,
      'long': 4,
      'very long': 5
    };
    return ratingValues[longevity.toLowerCase()] || 0; // Default to 0 if not found
  }, 
 
  priceToNumber(price) {
    const priceMap = {
      'very low': 1,
      'low': 2,
      'medium': 3,
      'high': 4,
      'very high': 5
    };
    return priceMap[price.toLowerCase()] || 0;
  },

getScentTypeColor,

GoLeft() {
  const container = this.$refs.chipsContainer;
  if (container) {
    container.scrollLeft -= 150;
  }
},
GoRight() {
  const container = this.$refs.chipsContainer;
  if (container) {
    container.scrollLeft += 150;
  }
},


goToDetails(image_name, id, name, brand, concentration, scentgn) {
  const description = this.formatForURL(`${name} ${concentration}`);
  const brand_clean = this.formatForURL(`${brand}`);
    this.$router.push({ name: 'PerfumeDetails', params: { image_name: image_name, id: id, brand:brand_clean, description: description, scentgn:scentgn} });
    },

    formatForURL(text) {
  return text.toString().toLowerCase()
    .normalize('NFD') 
    .replace(/\s+/g, '-')        // Replace spaces with hyphens
    .replace(/[^a-z0-9-]+/g, '') // Remove all non-word characters except hyphens without escaping
    .replace(/-{2,}/g, '-')      // Replace multiple hyphens with a single hyphen without escaping
    .replace(/^-+|-+$/g, '');    // Trim hyphens from the start and end without escaping
},

    
  },
};

</script>



<style scoped>

/* Pagination */

.custom-pagination >>> .v-pagination__item {
  border: none;
  box-shadow: none;
  background-color: transparent;
  color: #000; /* Adjust the color as needed */
}

.custom-pagination >>> .v-pagination__item--active {
  background-color: #000 !important; /* Active item background color */
  color: #ffffff; /* Active item text color */
  border-radius: 10px !important
}

.custom-pagination >>> .v-pagination__item--disabled {
  color: #b0b0b0; /* Adjust the disabled item color as needed */
}

.custom-pagination >>> .v-pagination__more {
  border: none;
  box-shadow: none;
  background-color: transparent;
  color: #000; /* Adjust the more button color as needed */
}

.custom-pagination >>> .v-pagination__navigation {
  border: none;
  box-shadow: none;
  background-color: transparent;
  color: #000; /* Adjust the arrow color as needed */
}

.custom-pagination >>> .v-pagination__navigation--disabled {
  color: #b0b0b0; /* Adjust the disabled arrow color as needed */
}


/* Keeps the chips color the same as non-hovered/non-focused */ 


.scrollable-chips-container .chips-row ::v-deep .v-chip {
  &:hover, &:focus {
    background-color: inherit !important; /* Keeps the background color the same as non-hovered/non-focused */    
  }
}

.scrollable-chips-container .chips-row ::v-deep .mobile-chip-strong-notes {
  &:hover, &:focus {
    background-color: #f8f8f8 !important; /* Keeps the background color the same as non-hovered/non-focused */    
  }
}


</style>
