import Vue from 'vue';
import Router from 'vue-router';
import StartPage from '@/components/StartPage.vue';
import PerfumeDataTable from '@/components/PerfumeDataTable.vue';
import ExplorePage from '@/components/ExplorePage.vue';
import ExploreAllPage from '@/components/ExploreAllPage.vue';
import { Capacitor } from '@capacitor/core';
import PrivacyPolicy from '@/components/PrivacyPolicy.vue';
//import ComparePrices from '@/components/ComparePrices.vue';
import ImageCarousel from '@/components/ImageCarousel.vue';
import ImageCarousel2 from '@/components/ImageCarousel2.vue';
import ImageCarousel3 from '@/components/ImageCarousel3.vue';
import PerfumeDetails from '@/components/PerfumeDetails.vue';
import FavoritesList from '@/components/FavoritesList.vue';
import StartInstructions from '@/components/StartInstructions.vue';


Vue.use(Router);

let savedPerfumesUrl = ''; // Variable to store the saved URL

const router = new Router({
  mode: 'history',
  routes: [
    {
      path: '/',
      name: 'StartPage',
      component: StartPage,
    },
    {
      path: '/explore',
      name: 'ExplorePage',
      component: ExplorePage,
    },
    {
      path: '/explore_all',
      name: 'ExploreAllPage',
      component: ExploreAllPage,
    },
    {
      path: '/perfumes',
      name: 'PerfumeDataTable',
      component: PerfumeDataTable,
    },
    {
      path: '/favorites',
      name: 'FavoritesList',
      component: FavoritesList,
    },
    {
      path: '/app-privacy',
      name: 'PrivacyPolicy',
      component: PrivacyPolicy,
    },
   // {
   //   path: '/prices', 
   //   name: 'ComparePrices',
   //   component: ComparePrices,
   // },
    {
      path: '/images', 
      name: 'ImageCarousel',
      component: ImageCarousel,
    },
    {
      path: '/images2', 
      name: 'ImageCarousel2',
      component: ImageCarousel2,
    },
    {
    path: '/images3', 
    name: 'ImageCarousel3',
    component: ImageCarousel3,
    },
    {
      path: '/instructions', 
      name: 'StartInstructions',
      component: StartInstructions,
    },
    {
      path: '/perfume-details/:brand/:scentgn/:image_name',
      name: 'PerfumeDetails',
      component: PerfumeDetails,
      props: true // Enable props passing based on route parameters
    },
  ],
  
scrollBehavior(to, from, savedPosition) {
    // Disable Vue Router's default saved position handling

    //console.log('index.js; scrollBehavior called');
    //console.log('index.js; From route:', from.fullPath);
    //console.log('index.js; To route:', to.fullPath);
   // console.log('index.js; Saved position:', savedPosition);

    if (savedPosition) {
      
     // console.log('index.js;Using saved position:', savedPosition);
      return false;
    }
   // console.log('index.js; No saved position, returning default.');
    return false; // Always let the app handle scroll position
  },
  
  

});

// Navigation guard to block StartPage navigation on iOS
router.beforeEach((to, from, next) => {
  const isIOSApp = Capacitor.isNativePlatform() && Capacitor.getPlatform() === 'ios';

  // Prevent navigation back to StartPage on iOS app if not directly accessing StartPage
  if (isIOSApp && to.path === '/' && from.path !== '/') {
    next(false); // Block navigation
  } else {
    next(); // Allow navigation
  }
});


  // Store the last route in sessionStorage to remember navigation history

router.beforeEach((to, from, next) => {
  sessionStorage.setItem('lastVisited', from.fullPath);
  console.log('index.js Last visited route saved in sessionStorage:', from.fullPath);
  next();
});


// Navigation guard
router.beforeEach((to, from, next) => {
  console.log('index.js; Navigation guard, for saving/restoring /perfumes URL');
  console.log('index.js; Navigation guard, From route:', from.fullPath);
  console.log('index.js; Navigation guard, To route:', to.fullPath);


  // Save the current `/perfumes` URL when leaving it, only if it has query parameters
  if (from.path === '/perfumes' && to.path !== '/perfumes' && from.fullPath.includes('?')) {
    savedPerfumesUrl = `${from.fullPath}`;
    console.log(`index.js navGuard; Leaving /perfumes with parameters. URL ${savedPerfumesUrl} was saved.`);
  } else if (from.path === '/perfumes' && to.path !== '/perfumes') {
    // Clear saved URL if leaving `/perfumes` without parameters
    savedPerfumesUrl = '';
    console.log(`index.js navGuard; Leaving /perfumes without parameters. URL was cleared.`);
  }

  // Redirect to saved URL when navigating to `/perfumes` without query parameters
  if (to.path === '/perfumes' && from.path !== '/perfumes' && savedPerfumesUrl && !to.fullPath.includes('?')) {
    console.log(`index.js; Redirecting to saved URL: ${savedPerfumesUrl}`);
    next(savedPerfumesUrl); // Redirect to the saved URL

    
  } else {
    console.log('index.js; No redirection, proceeding as usual.');
    next(); // Proceed as usual
  }
});



export default router;
